import axios from 'axios';

const tokenFace = 'EAACFmqZBPxooBOyMAoy81ptKvX2hSzH1XOZBNOXHxriZADIFcYj8t076ZCeFebGeC6ehP22d5JphxFr4Tes8y7zt2dq0uZANgaDAJHjfowZChVLC8Cjz8fbtsssM3yOanVl79WfVtTunI3GaBP6vlXsq0ocgpr6D6p4iU2T8Kpn36TiYrz7D9zaS15i5dLMbidfwHdvgk7yyeFcZBAZD';
const tokenInsta = 'IGQWRQUUVsUlJKamgzeE9LSlI1eHNOa0wxcHZAGcXp2eXZA3a1lBZAzA2N0dMRDhPZATZAKVVFRZATdDN0k5ZAlI4YUI5R0RERXlaLW1wcUZAxcVIzV3JCTlVJSDZAwWjZAHVVJ2OUZAFV25VbE1lVGdfSlZAHU0lDZATVhczBGbjQZD';

export const apiFacebook = axios.create({
  baseURL: 'https://graph.facebook.com/v9.0/me?fields=id%2Cname%2Clive_videos%7Bid%2Ctitle%2Cdescription%2Cstatus%2Cstream_url%2Csecure_stream_url%2Cembed_html%2Cvideo%7D&access_token='+tokenFace
});

export const apiInstagram = axios.create({
  baseURL: 'https://graph.instagram.com/me/media?fields=id,username,timestamp,media_type,caption,media_url,thumbnail_url,permalink&access_token='+tokenInsta
});
