import React, { useState, useEffect } from 'react';
import './style.css';

import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';

import Loader from '../../componentes/Loarder';
import { apiFacebook } from '../../services/api';

export default function Videos() {
    
  const [loade, setloade] = useState(true);
  const [datafacebook, setdatadatafacebook] = useState([]);

  // async function getData(){
  //   let returndatafacebook = await apiFacebook.get('');
  //   setdatadatafacebook(returndatafacebook.data);
  // }

  async function getData() {
    try {
      const returndatafacebook = await apiFacebook.get('');
      setdatadatafacebook(returndatafacebook.data);
      setloade(false);
    } catch (error) {
      console.error("Erro ao buscar dados do Facebook:", error);
      setloade(false);
    }
  }

  useEffect(async()=>{
    await getData();
    
  }, []);

  if(loade){
    return <Loader/>
  }else{
    return (
      <>
        <Header activeMenu="videos" />
        <div className="container-page">

          <div className="container-pag-videos">
            <div className="box-title">
                <h2>Vídeos</h2>
            </div>
            <div className="section">
              {
                datafacebook.live_videos &&

                datafacebook.live_videos.data.map( (v, k) =>{
                  return (
                    <div key={k} className="container-pag-videos">
                      <iframe key={k} src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fradiobragancaam%2Fvideos%2F${v.video.id}%2F&width=640`} width="100%"  scrolling="no" frameborder="0" allowfullscreen="true" allow="clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                    </div>
                  )
                })
              }
            </div>
          </div>

        </div>
        <Footer />
      </>
    );
  }
}